@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

* {
    margin: 0px;
    padding: 0px;
}

.main_screen {
    box-sizing: border-box;
    background-color: transparent   ;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0px;
}

.main_content::-webkit-scrollbar {
    display: none;
}
.main_content {
    padding: 24px;
    flex: 1;
    background-color: transparent;
    overflow: auto;
    width: 100vw;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

h1 {
    margin: 0px;
    height: fit-content;
    font-family: 'Anton', sans-serif;
    background-color: #4E2FC6;
    padding: 4px 0px 0px 0px;
    color: white;
}
.flex_1 {
    flex: 1;
}

.bottomBar {
    background-color: #4E2FC6;
    height:fit-content;
    width: 100%;
    margin: 0px;
    border: 0;
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.topBar {
    background-color: #4E2FC6;
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
}
