*{
    margin: 0px;
    padding: 0px;
}

.logoutBtn{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
}

.logoutBtn img{
    height: 20px;
    margin-right: 10px;
    display: none;
}