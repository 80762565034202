@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

* {
    margin: 0px;
    padding: 0px;
}

.main_screen {
    box-sizing: border-box;
    background-color: white;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.log_screen {
    flex: 1;
    box-sizing: border-box;
    width: 100vw;
    height: 100%;
    max-width: 300px;
    margin: 0px auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

h1 {
    margin: 0px;
    height: fit-content;
    font-family: 'Anton', sans-serif;
    background-color: #4E2FC6;
    padding: 4px 0px 0px 0px;
    color: white;
}
.flex_1 {
    flex: 1;
}
.centerContent{
    justify-content: space-evenly;
}

.bottomBar {
    background-color: #4E2FC6;
    height:40px;
    width: 100%;
    margin: 0px;
    border: 0;
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.topBar {
    background-color: #4E2FC6;
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
}

.video_main_content {
    padding: 24px;
    flex: 1;
    background-color: transparent;
    overflow: auto;
    width: 100vw;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tableInfo{
    max-width: 600px;
    border: 2px solid #4E2FC6;
    border-radius: 10px;
}

.claimBtn{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
}