* {
    margin: 0;
    padding: 0;
}

span{
    background-color: transparent;
}

.createSpan{
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.menuBtn {
    box-sizing: border-box;
    height: 40px;
    margin: 0px;
    width: fit-content;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.menuBtn img {
    height: 100%;
}
