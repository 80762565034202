* {
    margin: 0px;
    padding: 0px;
}

h2{
    color: #4E2FC6;
}
.loginCard {
    max-height: fit-content;
    width: 300px;
    border: 2px solid #4E2FC6;
    border-radius: 10px;
    padding: 16px;
    margin: 0 auto;
}

.formGroup {
    margin-bottom: 8px;
}

.btnBlue {
    background-color: #4E2FC6;
    color: white;
    font-weight: bold;
}
