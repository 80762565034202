@import url('https://fonts.googleapis.com/css2?family=Libre+Barcode+128&display=swap');

.PrizeContainer {
    display: flex;
    flex-flow: column nowrap;
    height: fit-content;
    background-color: transparent;
    width: 100%;
    max-width: 400px;
    padding: 8px;
}

.PrizeContainer h1{
    background-color: #4E2FC6;
    text-align: center;
    font-size: xx-large;
    text-align: center;
}

.barcode128{
    font-family: 'Libre Barcode 128', cursive;
    font-size: 4em;
    text-align: center;
}