.RollDice {
    display: flex;
    flex-flow: column nowrap;
    height: fit-content;
    background-color: transparent;
    width: 100%;
    max-width: 400px;
    padding: 8px;
}

/* Shows each dice in one row */
.RollDiceContainer {
    display: flex;
    justify-content: space-around;
    align-content: center;
}
/* Styling rolldice button */
.RollDice button {
    margin-top: 8px;
}

/* Setting hover effect on button */
.RollDice button:hover {
    cursor: pointer;
}

.diceVideos{
    width: 100%;
    max-width: 600px;
}