* {
    margin: 0px;
    padding: 0px;
}

.playDiv {
    box-sizing: border-box;
    width: 100%;
    max-width: 600px;
    margin: 8px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.playBtn {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.playBtn img {
    height: 100%;
}
