.loadingPage {
    background-color: white;
    height: 100vh;
    width: 100vw;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loadingPage img{
    width: 200px;
}
