* {
    margin: 0px;
    padding: 0px;
}

h2{
    color: #4E2FC6;
}
.loginCard {
    max-height: fit-content;
    width: 100%;
    border: 2px solid #4E2FC6;
    border-radius: 10px;
    padding: 16px;
    margin: 0 auto;

}

.formGroup {
    margin-bottom: 8px;
}

.btnBlue {
    background-color: #4E2FC6;
    color: white;
    font-weight: bold;
}
.Document{
    background-color: green;
    display: flex;
    justify-content: center;
}
.Page{
    background-color: red;
    width: 300px;
}
.PDFBody{
    width: 100%;
    height: 100%;
    overflow: hidden;
}