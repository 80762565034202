.LoadingMain {
    background-color: rgb(255, 255, 255);
    flex:1;
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0;
    left: 0;
}

.LoadingMain img{
    width: 200px;
}
