/* Styling each Die */
*{
    margin: 0px;
    padding: 0px;
}

.Dice {
    height: 40%;
    width: 40%;
    color: #4E2FC6;
}

/* Applying Animation */
.DiceShaking {
    animation-name: wobble;
    animation-duration: 1s;
}

/* Setting Animation effect to the 
     dice using css keyframe */
@keyframes wobble {
    from {
        transform: translate3d(0, 0, 0);
    }
    15% {
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }
    30% {
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }
    45% {
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }
    60% {
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }
    75% {
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}
