@import url("https://fonts.googleapis.com/css2?family=Koulen&display=swap");

* {
    margin: 0px;
    padding: 0px;
}

.main_screen {
    box-sizing: border-box;
    background-color: white;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.log_screen {
    flex: 1;
    box-sizing: border-box;
    width: 100vw;
    height: 100%;
    max-width: 300px;
    margin: 0px auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

h1 {
    margin: 0px;
    height: fit-content;
    font-family: "Koulen", cursive;
    background-color: #4E2FC6;
    padding: 4px 0px 0px 0px;
    color: white;
}
.flex_1 {
    flex: 1;
}

.bottomBar {
    background-color: #4E2FC6;
    height: 40px;
    width: 100%;
}

.topBar {
    background-color: #4E2FC6;
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
}

.previewVideo {
    width: 100%;
    max-width: 600px;
}
.AddMetricsformContainer {
    max-height: 50%;
    min-width: 300px;
    width: 50vw;
    max-width: 600px;
    border: 2px solid #4E2FC6;
    border-radius: 10px;
    padding: 16px;
    margin: 0 auto;
    overflow: auto;
}

.modalContainer {
    background-color: white;
    min-width: fit-content;
}

.AddMetricsTitle {
    display: flex;
    justify-content: space-between;
}
.AddMetricsFlex1 {
    flex: 1;
    display: flex;
    justify-content: end;
}
