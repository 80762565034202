@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

*{
    margin: 0px;
    padding: 0px;
}

.main_screen {
    box-sizing: border-box;
    background-color: white;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.log_screen {
    flex: 1;
    box-sizing: border-box;
    width: 100vw;
    height: 100%;
    max-width: 300px;
    margin: 0px auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}


.log_btn {
    width: 100%;
    margin: 8px 0px 8px 0px;
}

h1 {
    margin: 0;
    font-family: 'Anton', sans-serif;
    background-color: #4E2FC6;
    padding: 8px 0px 2px 0px;
    color: white;
}

.collapseContainer{
    max-height: 600px;
    overflow-y: auto;
    padding: 0px 0px 4px 0px;
}