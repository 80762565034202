* {
    margin: 0;
    padding: 0;
}

#homeSpan{
    background-color: transparent;
}

.homeBtn {
    box-sizing: border-box;
    height: 40px;
    margin: 0px;
    width: fit-content;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.homeBtn img {
    height: 100%;
}