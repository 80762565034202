.banner {
    position: absolute;
    bottom: 25px;
    display: flex;
    flex-direction: row;
}

.banner2 {
    position: relative;
    bottom: 0px;
    background-color: white;
    border: 2px solid #4e2fc6;
    padding: 4px;
    box-sizing: border-box;
    border-radius: 4px;
}
