.LoadingInfo {
    background-color: rgba(255, 255, 255, 0.724);
    height: 100vh;
    width: 100vw;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
}

.LoadingInfo img{
    width: 200px;
}
.message{
    background-color: #4E2FC6 !important;
    border-radius: 10px !important;
    color:white !important;
    padding: 2px 8px 2px 8px;
    margin-top: 4px;
}