* {
    margin: 0;
    padding: 0;
}

span {
    background-color: transparent;
}

.menuBtn {
    box-sizing: border-box;
    height: 40px;
    margin: 0px;
    width: fit-content;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.menuBtn img {
    height: 100%;
}

.userMenu {
    width: 250px;
    max-width: 250px;
    background-color: white;
}
.column {
    display: flex;
    flex-direction: column;
}

.columnInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.menuFooter{
    position: fixed;
    width: 218px;
    bottom: 0;
}