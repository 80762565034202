@import url('https://fonts.googleapis.com/css2?family=Libre+Barcode+128&display=swap');

.LoserContainer {
    display: flex;
    flex-flow: column nowrap;
    height: fit-content;
    background-color: transparent;
    width: 100%;
    max-width: 400px;
    padding: 8px;
}

.LoserContainer h1{
    color: rgb(21, 21, 21);
    background-color: white;
    text-align: center;
    font-size: xx-large;
    text-align: center;
}