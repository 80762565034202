*{
    margin: 0px;
    padding: 0px;
}

.promoContainer{
    box-sizing: border-box;
    width: fit-content;
    max-width: 600px;
    background-color: white;
    border: 2px solid #4E2FC6;
    border-radius: 10px 10px 0px 0px;
    margin: 8px;
}

.h1{
    background-color: transparent;
    color: #4E2FC6;
    text-align: center;
}